<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <AppTitle :title="`Liquidaciones de pago Tiendas`" subtitle="---"> </AppTitle>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-data-table
              :headers="header"
              :items="rows"
              class="elevation-0"
              no-data-text="No hay liquidaciones ingresadas"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left" style="cursor:pointer;" @click="edit(props.item)">
                  {{ props.item.store.name }}
                </td>
                <td class="text-xs-left" style="cursor:pointer;" @click="edit(props.item)">
                  {{ props.item.month }} / {{ props.item.year }}
                </td>
                <td class="text-xs-right" style="cursor:pointer;" @click="edit(props.item)">
                  {{ props.item.pagarTotal | toPrice }}
                </td>
                <td class="text-xs-right">
                  <v-btn
                    v-if="props.item.document"
                    class="ma-0"
                    color="accent"
                    :href="props.item.document"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.transfer"
                    class="ma-0"
                    color="secondary"
                    :href="props.item.transfer"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.factura"
                    class="ma-0"
                    color="error"
                    :href="props.item.factura"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog v-model="dialog" scrollable max-width="350px">
      <v-card>
        <TitleDialog
          :title="!form.id ? 'Crear Liquidacion' : `Liquidacion #${form.id}`"
          :close-action="() => (dialog = false)"
          close
        >
        </TitleDialog>
        <v-card-text style="max-height:600px">
          <v-container class="pa-2" grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12>
                <v-subheader>
                  1- Seleccione una Tienda
                </v-subheader>
                <v-select
                  v-model="form.store"
                  :items="stores"
                  label="Tiendas"
                  item-text="name"
                  item-value="id"
                  return-object
                  hide-details
                >
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-subheader>
                  2- Seleccione mes y año
                </v-subheader>
                <v-layout>
                  <v-flex xs6>
                    <v-select
                      v-model="form.month"
                      :items="months"
                      :disabled="!form.store"
                      label="Mes"
                      item-text="name"
                      item-value="id"
                      hide-details
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="form.year"
                      :items="years"
                      :disabled="!form.store"
                      label="Año"
                      item-text="name"
                      item-value="id"
                      hide-details
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-if="form.year && form.month && form.store" xs12>
                <v-layout row wrap>
                  <v-flex class="py-0" xs12>
                    <v-subheader>
                      3- Descuentos o aumentos (opcional)
                    </v-subheader>
                  </v-flex>
                  <v-flex class="py-0" xs6>
                    <v-text-field v-model="nameDiscount" flat label="Nombre"></v-text-field>
                  </v-flex>
                  <v-flex class="py-0" xs4>
                    <v-text-field v-model="priceDiscount" flat prefix="$" label="Valor"></v-text-field>
                  </v-flex>
                  <v-flex class="py-0" xs2>
                    <v-btn icon class="mt-3" color="primary" @click="addDiscount">
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <template v-for="(discount, index) in discounts">
                  <v-chip :key="index" close @input="deleteDiscount(discount)">
                    {{ discount.name }} ({{ discount.price | toPrice }})
                  </v-chip>
                </template>
              </v-flex>
              <v-flex v-if="form.year && form.month && form.store" xs12>
                <v-subheader>
                  4- Detalle de valores
                </v-subheader>
                <v-text-field v-model="form.montoTotal" flat label="Total Ventas" disabled prefix="$"></v-text-field>
                <v-text-field
                  v-model="form.comision"
                  flat
                  disabled
                  prefix="$"
                  :label="`Comision ${user.store.discount}%`"
                ></v-text-field>
                <v-text-field v-model="form.comisionIVA" flat disabled prefix="$" label="Comision IVA"></v-text-field>
                <v-text-field
                  v-model="form.descuentoTotal"
                  flat
                  disabled
                  prefix="$"
                  label="Insumos solicitados"
                ></v-text-field>
                <template v-if="otros !== 0">
                  <v-text-field v-model="otros" flat disabled prefix="$" label="Otros"></v-text-field>
                </template>
                <v-text-field
                  v-model="form.pagarTotal"
                  flat
                  disabled
                  prefix="$"
                  label="Monto a pagar"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialog = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!form.year || !form.month || !form.store" color="accent" flat @click="generate">
            Generar liquidacion
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="1000px">
      <v-card>
        <TitleDialog
          :title="!form.id ? 'Crear Liquidacion' : `Liquidacion #${form.id}`"
          :close-action="() => (dialogEdit = false)"
          close
        >
        </TitleDialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs4>
              <v-select
                v-model="form.store"
                :items="stores"
                label="Tienda"
                item-text="name"
                item-value="id"
                return-object
                disabled
                hide-details
              >
              </v-select>
            </v-flex>
            <v-flex xs4>
              <v-select
                v-model="form.month"
                :items="months"
                disabled
                label="Mes"
                item-text="name"
                item-value="id"
                hide-details
              >
              </v-select>
            </v-flex>
            <v-flex xs4>
              <v-select
                v-model="form.year"
                :items="years"
                disabled
                label="Año"
                item-text="name"
                item-value="id"
                hide-details
              >
              </v-select>
            </v-flex>
            <v-flex xs6>
              <v-textarea
                v-model="form.datos_bancarios"
                disabled
                rows="5"
                label="Datos Bancarios"
                placeholder="Banco: Estado
Tipo cuenta: Vista
Cta cte: xxxxxxxx
Rut: xxxxxxx-x
Email: email@email.com"
                auto-grow
              ></v-textarea>
            </v-flex>
            <v-flex xs6>
              <v-textarea
                v-model="form.datos_facturacion"
                disabled
                rows="5"
                label="Datos Facturacion"
                placeholder="Rut, Razon social, giro, direccion, comuna, etc"
                auto-grow
              ></v-textarea>
            </v-flex>
            <v-flex
              v-if="form.year && form.month && form.store && (visible.discount || visible.order || visible.otros)"
              xs8
            >
              <template v-if="visible.order">
                <v-data-table
                  :headers="[
                    { text: 'Pedido', align: 'left', sortable: false },
                    { text: 'Cliente', align: 'left', sortable: false },
                    { text: 'Total', align: 'right', sortable: false },
                    { text: 'Comuna', sortable: false }
                  ]"
                  :items="form.orders"
                  :rows-per-page-items="rowsPerPage"
                  class="elevation-0"
                >
                  <template slot="items" slot-scope="props">
                    <td class="text-xs-left">
                      <v-btn
                        depressed
                        color="accent"
                        target="_blank"
                        :to="{
                          name: 'orderIndex',
                          params: { id: props.item.id }
                        }"
                        small
                      >
                        {{ props.item.id }}
                      </v-btn>
                    </td>
                    <td class="text-xs-left" v-html="props.item.name"></td>
                    <td class="text-xs-right">
                      {{ props.item.valor | toPrice }}
                    </td>
                    <td class="text-xs-left" v-html="props.item.comuna"></td>
                  </template>
                </v-data-table>
              </template>
              <template v-if="visible.discount">
                <v-data-table
                  :headers="[
                    { text: 'Solicitud', align: 'left', sortable: false },
                    { text: 'Nombre', align: 'left', sortable: false },
                    { text: 'Cantidad', align: 'right', sortable: false },
                    { text: 'Precio', align: 'right', sortable: false },
                    { text: 'Total', align: 'right', sortable: false }
                  ]"
                  :items="form.discount"
                  :rows-per-page-items="rowsPerPage"
                  class="elevation-0"
                >
                  <template slot="items" slot-scope="props">
                    <td class="text-xs-left"># {{ props.item.solicitud }}</td>
                    <td class="text-xs-left" v-html="props.item.name"></td>
                    <td class="text-xs-right">{{ props.item.quantity }}</td>
                    <td class="text-xs-right">
                      {{ props.item.price | toPrice }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.value | toPrice }}
                    </td>
                  </template>
                </v-data-table>
              </template>
              <template v-if="visible.otros">
                <v-data-table
                  :headers="[
                    { text: 'Nombre', align: 'left', sortable: false },
                    { text: 'Precio', align: 'right', sortable: false }
                  ]"
                  :items="discounts"
                  :rows-per-page-items="rowsPerPage"
                  class="elevation-0"
                >
                  <template slot="items" slot-scope="props">
                    <td class="text-xs-left" v-html="props.item.name"></td>
                    <td class="text-xs-right">
                      {{ props.item.price | toPrice }}
                    </td>
                  </template>
                </v-data-table>
              </template>
            </v-flex>
            <v-flex v-if="form.year && form.month && form.store" auto>
              <v-text-field
                v-model="form.montoTotal"
                flat
                label="Total Ventas"
                prefix="$"
                readonly
                :append-outer-icon="visible.order ? 'grid_on' : 'grid_off'"
                hide-details
                @click:append-outer="visibleOrder"
              ></v-text-field>
              <v-text-field
                v-model="form.comision"
                flat
                disabled
                prefix="$"
                label="Comision 10%"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="form.comisionIVA"
                flat
                disabled
                prefix="$"
                label="Comision IVA"
                hide-details
              ></v-text-field>
              <v-text-field
                v-model="form.descuentoTotal"
                flat
                readonly
                prefix="$"
                label="Insumos solicitados"
                :append-outer-icon="visible.discount ? 'grid_on' : 'grid_off'"
                hide-details
                @click:append-outer="visibleDiscount"
              ></v-text-field>
              <template v-if="otros !== 0">
                <v-text-field
                  v-model="otros"
                  flat
                  readonly
                  prefix="$"
                  :append-outer-icon="visible.otros ? 'grid_on' : 'grid_off'"
                  label="Otros"
                  @click:append-outer="visibleOtros"
                ></v-text-field>
              </template>
              <v-text-field
                v-model="form.pagarTotal"
                flat
                disabled
                prefix="$"
                label="Monto a pagar"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="text-xs-right">
              <FileReader
                class="mr-2"
                color="secondary"
                name="Transferencia"
                :flat="false"
                :block="false"
                :get-file="getFileTransfer"
                @load="transfer = $event"
              >
              </FileReader>
              <FileReader name="Documento" :flat="false" :block="false" :get-file="getFile" @load="file = $event">
              </FileReader>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" flat @click="deleteAction(form)">
            Eliminar liquidacion
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="form.factura" flat class="ma-0 mr-2" color="error" :href="form.factura" target="_blank">
            <v-icon left>cloud_download</v-icon> Factura
          </v-btn>
          <v-btn v-if="form.transfer" flat class="ma-0 mr-2" color="secondary" :href="form.transfer" target="_blank">
            <v-icon left>cloud_download</v-icon> Transferencia
          </v-btn>
          <v-btn v-if="form.document" flat class="ma-0" color="accent" :href="form.document" target="_blank">
            <v-icon left>cloud_download</v-icon> Documento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn dark fixed bottom right color="accent" @click="createLiquidacion({})">
      <v-icon left>add</v-icon>
      Crear Liquidacion
    </v-btn>
  </v-layout>
</template>

<script>
import AppTitle from '../useful/title.vue'
import TitleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'
import { MI_STORE } from '../../config'

export default {
  name: 'StorePayStoreAdmin',
  components: { AppTitle, TitleDialog, FileReader },
  data() {
    return {
      transfer: '',
      transferDoc: '',
      file: '',
      fileDoc: '',
      type: 3,
      rows: [],
      discounts: [],
      stores: [],
      loading: false,
      dialog: false,
      dialogEdit: false,
      nameDiscount: null,
      priceDiscount: null,
      form: {
        discounts: null,
        month: null,
        year: null,
        store: null,
        montoTotal: null,
        comision: null,
        comisionIVA: null,
        descuentoTotal: null,
        pagarTotal: null
      },
      visible: {
        order: false,
        discount: false,
        otros: false
      },
      pagination: {
        sortBy: 'variacion',
        rowsPerPage: 5
      },
      rowsPerPage: [5, 25, 50, 100, 200, { text: 'Todos', value: -1 }],
      totalRow: 0,
      dateStart: this.$moment()
        .subtract(360, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment().format('YYYY-MM-DD'),
      header: [
        { text: 'Tienda', align: 'left', sortable: false },
        { text: 'Mes', align: 'left', sortable: false },
        {
          text: 'Total',
          align: 'right',
          value: 'normalPrice',
          sortable: false
        },
        {
          text: 'Documentos',
          align: 'right',
          value: 'highPrice',
          sortable: false
        }
      ]
    }
  },
  computed: {
    months() {
      const months = []
      let i = 1
      this.$moment.months().forEach(element => {
        months.push({ id: i, name: element })
        i += 1
      })
      return months
    },
    years() {
      const start = 2019
      const end = new Date().getFullYear()
      const years = []
      for (let i = start; i <= end; i++) {
        years.push(i)
      }
      return years
    },
    user() {
      return this.$store.getters.getUser
    },
    otros() {
      return parseInt(this._.sumBy(this.discounts, 'price'), 10)
    }
  },
  watch: {
    'form.year': function year() {
      if (!this.dialogEdit) {
        this.searchDetail()
      }
    },
    'form.month': function year() {
      if (!this.dialogEdit) {
        this.searchDetail()
      }
    }
  },
  mounted() {
    this.getProductsPrice()
  },
  methods: {
    async searchDetail() {
      if (this.form.month && this.form.year && this.form.store) {
        try {
          const res = await this.$http.get(
            `${MI_STORE}/liquidacion/search?${this.$qs.stringify({
              form: this.form
            })}`
          )
          this.setDetail(res.data.orders, res.data.discount)
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async getProductsPrice() {
      try {
        this.loading = true
        const res = await this.$http(`${MI_STORE}/liquidacion/pay-store-admin`)
        this.loading = false
        if (res.data !== undefined) {
          this.rows = res.data.facturacion
          this.stores = res.data.stores
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async generate() {
      try {
        await this.$http.post(`${MI_STORE}/liquidacion/pay-store-admin`, {
          form: this.form,
          otros: this.discounts
        })
        this.dialog = false
        this.getProductsPrice()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(item) {
      try {
        await this.$http.delete(`${MI_STORE}/liquidacion/pay-store-admin/${item.id}`, {
          item
        })
        this.dialogEdit = false
        this.getProductsPrice()
        this.$store.dispatch('setConfirm', { active: false })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        const res = await this.$http.post(`${MI_STORE}/liquidacion/pay-store-admin/${this.form.id}/file`, formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        this.form.document = res.data
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Subido correctamente'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente...'
        })
      }
    },
    async getFileTransfer(transfer) {
      const formData = new FormData()
      formData.append('file', transfer)
      formData.append('p', 'uploadFileTransfer')
      try {
        this.$http.post(`${MI_STORE}/liquidacion/pay-store-admin/${this.form.id}/transfer`, formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        this.form.transfer = res.data.json
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Subido correctamente'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente...'
        })
      }
    },
    deleteDiscount(index) {
      this.discounts.splice(this.discounts.indexOf(index), 1)
      this.discounts = [...this.discounts]
    },
    addDiscount() {
      this.discounts.push({
        name: this.nameDiscount,
        price: parseInt(this.priceDiscount, 10)
      })
      this.form.pagarTotal = this.form.pagarTotalOriginal + this.otros
      this.nameDiscount = null
      this.priceDiscount = null
    },
    visibleDiscount() {
      this.visible.discount = !this.visible.discount
    },
    visibleOrder() {
      this.visible.order = !this.visible.order
    },
    visibleOtros() {
      this.visible.otros = !this.visible.otros
    },
    edit(item) {
      this.dialogEdit = true
      this.form = item
      this.getDetail(item.id)
    },
    async getDetail(id) {
      try {
        this.loading = true
        const res = await this.$http(`${MI_STORE}/liquidacion/pay-store-admin/${id}`)
        this.loading = false
        if (res.data !== undefined) {
          const row = res.data.facturacion[0]
          this.form = row
          this.discounts = row.discounts
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    setDetail(orders, discounts) {
      this.form.montoTotal = this._.sumBy(orders, 'valor')
      this.form.comision = parseInt(((this.form.montoTotal * this.form.store.discount) / 100).toFixed(0), 10)
      this.form.comisionIVA = parseInt(((this.form.comision * 19) / 100).toFixed(0), 10)
      this.form.descuentoTotal = this._.sumBy(discounts, 'value')
      this.form.pagarTotal =
        this.form.montoTotal - (this.form.descuentoTotal + this.form.comision + this.form.comisionIVA)
      this.form.pagarTotalOriginal = this.form.pagarTotal
    },
    createLiquidacion() {
      this.dialog = true
      this.form = {
        month: null,
        year: null,
        store: null,
        montoTotal: null,
        comision: null,
        comisionIVA: null,
        descuentoTotal: null,
        pagarTotal: null
      }
      this.discounts = []
    },
    deleteAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar Liquidacion?',
        description: 'Se eliminara del sistema, no se podra recuperar',
        action: () => this.deleteItem(item)
      })
    }
  }
}
</script>
